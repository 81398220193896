<!-- eslint-disable vue/no-v-html -->
<template>
	<b-modal
		ref="registerPackProductsModal"
		scrollable
		hide-footer
		header-class="mx-auto w-100 bg-dark text-white"
		:size="['xs', 'sm'].includes(windowWidth) ? 'xl' : 'lg-custom'"
		:body-class="['xs'].includes(windowWidth) ? 'p-2' : ''"
		centered
		@hide="close">
		<template v-slot:modal-header>
			<h5 class="modal-title text-white">
				{{ translate(packId + '_pack') }}
			</h5>
			<button
				type="button"
				aria-label="Close"
				class="close text-white"
				@click="close">
				×
			</button>
		</template>
		<div>
			<div class="col col-auto text-center">
				<span> {{ translate(packId + '_description') }} </span>
			</div>
		</div>
		<hr class="m-3">
		<div
			class="row no-gutters justify-content-center">
			<div
				:class="['xs', 'sm'].includes(windowWidth) ? '' : 'px-2'"
				class="col-12">
				<div
					class="border-secondary mb-2 px-2 pt-2 pb-0"
					style="border: 1px solid black; border-radius: 5px">
					<div class="row mx-2 mt-1 mb-">
						<div class="col-12">
							<div class="col-12">
								<div
									v-for="(product, i) in packProducts"
									:key="i"
									:class="i < packProducts.length - 1 ? 'border border-secondary border-top-0 border-left-0 border-right-0 mb-2' : ''">
									<strong> {{ product.name }} </strong>
									<div
										v-if="product.fully_customizable"
										class="row pb-3 pl-2 pt-1">
										<div class="col-12">
											<div>
												<img
													:src="product.image"
													width="50px"
													class="img-fluid">
												<span
													class="ml-2 pl-1"
													v-html="translate('customizable_choice', { bvs: product.bvs })" />
											</div>
										</div>
									</div>
									<div
										v-if="product.qty"
										class="row pb-3 pl-2 pt-1">
										<div class="col-12 col-md-6">
											<div>
												<img
													:src="product.image === null ? product.children[0].image : product.image"
													width="50px"
													class="img-fluid">
												<span
													class="ml-2">
													<strong> {{ product.qty }} x </strong> {{ translate(product.selection_type) }}
												</span>
											</div>
										</div>
									</div>
									<div
										v-else
										class="row pb-3 pl-2 pt-1">
										<div
											v-for="(child, j) in product.children"
											:key="j"
											class="col-12 col-md-6">
											<div>
												<img
													:src="child.image"
													width="50px"
													class="img-fluid">
												<span
													v-if="child.qty"
													class="ml-2">
													<strong> {{ child.qty }} x </strong> {{ getOnlyConfigurationName(child.name) }}
												</span>
												<span v-else>{{ getOnlyConfigurationName(child.name) }} </span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</b-modal>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';
import { Purchase } from '@/translations';
import EventBus from '@/util/eventBus';
import ConfigurableProducts from '@/mixins/ConfigurableProducts';

export default {
	name: 'RegisterPackProductsModal',
	messages: [Purchase],
	mixins: [WindowSizes, ConfigurableProducts],
	props: {
		packProducts: {
			type: Array,
			default: () => ({}),
		},
		packId: {
			type: String,
			default: () => (''),
		},
		type: {
			type: String,
			default: () => (''),
		},
	},
	mounted() {
		EventBus.$on('showRegisterPackProductsModal', () => {
			this.open();
		});
		EventBus.$on('hideRegisterPackProductsModal', () => {
			this.close();
		});
	},
	beforeDestroy() {
		EventBus.$off('showRegisterPackProductsModal');
		EventBus.$off('hideRegisterPackProductsModal');
	},
	methods: {
		open() {
			this.$refs.registerPackProductsModal.show();
		},
		close() {
			this.$refs.registerPackProductsModal.hide();
			this.$emit('close');
		},
	},
};
</script>
